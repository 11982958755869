@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-color: #0c0d13;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  /* background-color: #27c456 !important; */
  background: linear-gradient(
    93.3deg,
    #f143e2 2.36%,
    #40187f 99.66%
  ) !important;
  /* border-radius: "6px";
  border: "0px";
  color: "white";  */
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background: linear-gradient(
    93.3deg,
    #f143e2 2.36%,
    #40187f 99.66%
  ) !important;
}

.createDateRangePicker
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #fcbbea !important;
}

.createDateRangePicker .ant-btn-primary {
  background: linear-gradient(
    93.3deg,
    #f143e2 2.36%,
    #40187f 99.66%
  ) !important;
  border-color: linear-gradient(
    93.3deg,
    #f143e2 2.36%,
    #40187f 99.66%
  ) !important;
}
.ant-picker-input > input {
  color: white !important;
}

.ant-picker-input > input:disabled {
  color: grey !important;
}

.ant-picker-suffix {
  color: white !important;
}

.css-yjsfm1 {
  font-size: 10px !important;
}

.ant-picker-separator {
  color: white !important;
  margin-bottom: 8px !important;
}

.input-field:hover {
  outline: #f143e2 solid 1px !important;
}

.input-field:focus-visible {
  outline: #f143e2 solid 1px !important;
}

.ant-picker {
  border: 1px solid transparent !important;
}

.ant-picker:hover {
  border: 1px solid #f143e2 !important;
}

.ant-picker:focus-within {
  border: 1px solid #f143e2 !important;
}

.ant-picker-active-bar {
  background: #f143e2 !important;
}

.logo {
  width: 150px;
  height: 30px;
}

@media (min-width: 600px) and (max-width: 768px) {
  /* Increase width on tablet screens */
  .logo {
    width: 120px;
    height: 25px;
  }
}

@media (max-width: 599px) {
  /* Keep the same width on mobile screens */
  .logo {
    width: 90px;
    height: 20px;
  }
}
